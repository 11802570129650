<template>
  <div class="vx-col w-full">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card title="เลือกวันเวลา">
          <label>เริ่มต้น: </label>
          <flat-pickr
            :config="configdateTimePicker"
            v-model="before_datetime"
            placeholder="Date Time"
          />
          <br />
          <br />
          <label> สิ้นสุด: </label>
          <flat-pickr
            :config="configdateTimePicker"
            v-model="after_datetime"
            placeholder="Date Time"
          />
          <br />
          <span class="text-danger">* ไม่ควรเลือกเกิน 2 วัน</span>
          <br />
          <vs-button color="primary" @click="selectdate_time()"
            >ค้นหา</vs-button
          >
        </vx-card>
        <br />
        <vx-card>
          <vs-button
            class=""
            color="primary"
            @click="popupAllmessage(dataCallcenter)"
            >ส่งข้อความทุกคน</vs-button
          >
          <vs-table
            stripe
            max-items="20"
            pagination
            :data="dataCallcenter"
            search
          >
            <template slot="thead">
              <vs-th>วันเวลาที่สมัคร</vs-th>
              <vs-th>ยูสเซอร์เนม</vs-th>
              <vs-th>เบอร์โทรศัพท์</vs-th>
              <vs-th>ชื่อ-นามสกุล</vs-th>
              <vs-th>Line</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.member_register_date">
                  {{
                    moment(tr.member_register_date).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}
                </vs-td>
                <vs-td class="text-danger"
                  ><router-link
                    :to="`/member/${data[indextr].member_username}`"
                    >{{ data[indextr].member_username }}</router-link
                  ></vs-td
                >

                <vs-td :data="tr.member_phone">
                  {{ tr.member_phone }}
                </vs-td>
                <vs-td :data="tr.member_name">
                  {{ tr.member_name }} {{ tr.member_surname }}
                </vs-td>
                <vs-td :data="tr.member_line">
                  {{ tr.member_line }}
                </vs-td>

                <div style="display: flex; align-items: center">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-check-circle"
                    class="mr-2"
                    color="warning"
                    @click="
                      popupMessage(
                        tr,
                        tr.member_phone,
                        tr.member_name,
                        tr.member_surname,
                        tr.member_line,
                        tr.member_register_date,
                        tr.member_username
                      )
                    "
                    v-model="tr.member_username"
                    >ส่งข้อความ</vs-button
                  >
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-check-circle"
                    class="mr-2"
                    color="success"
                    @click="
                      popupConfirm(
                        tr.member_phone,
                        tr.member_name,
                        tr.member_surname,
                        tr.member_line,
                        tr.member_register_date,
                        tr.member_username
                      )
                    "
                    v-model="tr.member_username"
                    >ยืนยัน</vs-button
                  >

                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-check-circle"
                    class="mr-2"
                    color="danger"
                    @click="
                      popupNoconfirm(
                        tr.member_phone,
                        tr.member_name,
                        tr.member_surname,
                        tr.member_line,
                        tr.member_register_date,
                        tr.member_username
                      )
                    "
                    v-model="tr.member_username"
                    >ติดปัญหา</vs-button
                  >
                </div>
              </vs-tr>
            </template>
          </vs-table>
          <vs-popup
            classContent="popup-example"
            title="ส่งข้อความหาทุกคน"
            :active.sync="popup_allmessage"
          >
            <vx-input-group class="mb-base">
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>ข้อมูล</span>
                </div>
              </template>

              <vs-select class="w-full mt-full" v-model="selectedMessage">
                <vs-select-item
                  :key="index"
                  :value="item.text"
                  :text="item.text"
                  v-for="(item, index) in infoSettingMessage"
                />
              </vs-select>
            </vx-input-group>
            <vs-button
              @click="message(selectedMessage)"
              color="primary"
              type="filled"
              >ยืนยัน</vs-button
            >
          </vs-popup>
          <vs-popup
            classContent="popup-example"
            title="ส่งข้อความ"
            :active.sync="popup_message"
          >
            <h4>
              เบอร์โทรศัพท์ที่ติดต่อ:
              <b style="color: #f61340">{{ member_phone }}</b>
            </h4>
            <h4>
              ชื่อ-นามสกุล:
              <b style="color: #f61340"
                >{{ member_name }} {{ member_surname }}</b
              >
            </h4>
            <h4>
              LineID: <b style="color: #f61340">{{ member_line }} </b>
            </h4>
            <br />
            <vx-input-group class="mb-base">
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>ข้อมูล</span>
                </div>
              </template>

              <vs-select class="w-full mt-full" v-model="selectedMessage">
                <vs-select-item
                  :key="index"
                  :value="item.text"
                  :text="item.text"
                  v-for="(item, index) in infoSettingMessage"
                />
              </vs-select>
            </vx-input-group>
            <vs-button
              @click="message(selectedMessage)"
              color="primary"
              type="filled"
              >ยืนยัน</vs-button
            >
          </vs-popup>
          <vs-popup
            classContent="popup-example"
            title="กรุณากรอกข้อมูล"
            :active.sync="popup_conFrim"
          >
            <h4>
              เบอร์โทรศัพท์ที่ติดต่อ:
              <b style="color: #f61340">{{ member_phone }}</b>
            </h4>
            <h4>
              ชื่อ-นามสกุล:
              <b style="color: #f61340"
                >{{ member_name }} {{ member_surname }}</b
              >
            </h4>
            <h4>
              LineID: <b style="color: #f61340">{{ member_line }} </b>
            </h4>
            <br />
            <vx-input-group class="mb-base">
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>ข้อมูล</span>
                </div>
              </template>

              <vs-input
                ref="inputPassword"
                placeholder="กรุณากรอกข้อมูล"
                v-model="info"
              />
            </vx-input-group>
            <vs-button
              @click="
                confirm(
                  member_phone,
                  member_name,
                  member_surname,
                  member_line,
                  member_register_date,
                  member_username,
                  info
                )
              "
              color="primary"
              type="filled"
              >ยืนยัน</vs-button
            >
          </vs-popup>
          <vs-popup
            classContent="popup-example"
            title="กรุณากรอกข้อมูล"
            :active.sync="popup_warningConfirm"
          >
            <h4>
              เบอร์โทรศัพท์ที่ติดต่อ:
              <b style="color: #f61340">{{ member_phone }}</b>
            </h4>
            <h4>
              ชื่อ-นามสกุล:
              <b style="color: #f61340"
                >{{ member_name }} {{ member_surname }}</b
              >
            </h4>
            <h4>
              LineID: <b style="color: #f61340">{{ member_line }} </b>
            </h4>
            <br />
            <vx-input-group class="mb-base">
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>ข้อมูล</span>
                </div>
              </template>

              <vs-input
                ref="inputPassword"
                placeholder="กรุณากรอกข้อมูล"
                v-model="info"
              />
            </vx-input-group>
            <vs-button
              @click="
                warningConfirm(
                  member_phone,
                  member_name,
                  member_surname,
                  member_line,
                  member_register_date,
                  member_username,
                  info
                )
              "
              color="primary"
              type="filled"
              >ยืนยัน</vs-button
            >
          </vs-popup>
        </vx-card>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  data () {
    return {
      admin_user: this.$store.state.AppActiveUser,
      before_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD 00:00'),
      after_datetime: moment
        .tz('Asia/Bangkok')
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm'),
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      dataCallcenter: [],
      username: '',
      registerDate: '',
      status_usebank: '',
      popup_conFrim: false,
      popup_warningConfirm: false,
      popup_message: false,
      popup_allmessage: false,
      info: '',
      member_line: '',
      member_phone: '',
      member_name: '',
      member_surname: '',
      member_username: '',
      selectedMessage: [],
      infoSettingMessage: [],
      memberData: []
    }
  },
  components: {
    flatPickr,
    StatisticsCardLine,
    vSelect
  },
  async mounted () {
    // eslint-disable-next-line no-unused-expressions
    await axios
      .get(`callcenter/list/${this.before_datetime}/${this.after_datetime}`)
      .then((response) => (this.dataCallcenter = response.data)),
    await this.callinfo()
  },
  methods: {
    async selectdate_time () {
      await axios
        .get(`callcenter/list/${this.before_datetime}/${this.after_datetime}`)
        .then((response) => (this.dataCallcenter = response.data))
    },
    callinfo () {
      axios
        .get('/callcenter/setting/list/message')
        .then((response) => (this.infoSettingMessage = response.data))
    },
    async popupConfirm (
      member_phone,
      member_name,
      member_surname,
      member_line,
      member_register_date,
      member_username
    ) {
      this.member_phone = member_phone
      this.member_name = member_name
      this.member_surname = member_surname
      this.member_line = member_line
      this.member_register_date = member_register_date
      this.member_username = member_username
      this.popup_conFrim = true
      this.$nextTick(() => {
        this.$refs.inputPassword.$el.querySelector('input').focus()
      })
    },
    async popupMessage (
      tr,
      member_phone,
      member_name,
      member_surname,
      member_line,
      member_register_date,
      member_username
    ) {
      this.memberData = [tr]
      this.member_phone = member_phone
      this.member_name = member_name
      this.member_surname = member_surname
      this.member_line = member_line
      this.member_register_date = member_register_date
      this.member_username = member_username
      this.popup_message = true
      this.$nextTick(() => {
        this.$refs.inputPassword.$el.querySelector('input').focus()
      })
    },
    async popupAllmessage (dataCallcenter) {
      this.memberData = dataCallcenter
      this.popup_allmessage = true
      this.$nextTick(() => {
        this.$refs.inputPassword.$el.querySelector('input').focus()
      })
    },
    async message (selectedMessage) {
      console.log('plathong')
      await axios
        .post('callcenter/sendMessage', {
          memberData: this.memberData,
          selectedMessage
        })
        .then((response) => (this.status_confirm = response.data))
      if (this.status_confirm.status === true) {
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.status_confirm.info.title,
          text: this.status_confirm.info.info
        })
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.agent_error.info.title,
          text: this.agent_error.info.info
        })
      }
      this.info = ''
      this.popup_message = false
      this.popup_allmessage = false
      await this.selectdate_time(this.before_datetime, this.after_dateitme)
    },
    async confirm (
      member_phone,
      member_name,
      member_surname,
      member_line,
      member_register_date,
      member_username,
      info
    ) {
      await axios
        .post('callcenter/confirm', {
          member_phone,
          member_name,
          member_surname,
          member_line,
          member_register_date,
          member_username,
          info,
          status: 1
        })
        .then((response) => (this.status_confirm = response.data))
      if (this.status_confirm.status === true) {
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.status_confirm.info.title,
          text: this.status_confirm.info.info
        })
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.agent_error.info.title,
          text: this.agent_error.info.info
        })
      }
      this.info = ''
      this.popup_conFrim = false
      await this.selectdate_time(this.before_datetime, this.after_dateitme)
    },
    async warningConfirm (
      member_phone,
      member_name,
      member_surname,
      member_line,
      member_register_date,
      member_username,
      info
    ) {
      await axios
        .post('callcenter/confirm', {
          member_phone,
          member_name,
          member_surname,
          member_line,
          member_register_date,
          member_username,
          info,
          status: 2
        })
        .then((response) => (this.status_confirm = response.data))
      if (this.status_confirm.status === true) {
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.status_confirm.info.title,
          text: this.status_confirm.info.info
        })
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.agent_error.info.title,
          text: this.agent_error.info.info
        })
      }
      this.info = ''
      this.popup_warningConfirm = false
      await this.selectdate_time(this.before_datetime, this.after_dateitme)
    },
    async popupNoconfirm (
      member_phone,
      member_name,
      member_surname,
      member_line,
      member_register_date,
      member_username
    ) {
      this.member_phone = member_phone
      this.member_name = member_name
      this.member_surname = member_surname
      this.member_line = member_line
      this.member_register_date = member_register_date
      this.member_username = member_username
      this.popup_warningConfirm = true
      this.$nextTick(() => {
        this.$refs.inputPassword.$el.querySelector('input').focus()
      })
    }
  }
}
</script>

<style></style>
